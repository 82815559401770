<template>
  <div class="helper">
    <!-- <div class="nav"></div> -->
    <topNav :active="pageIndex" :historyActive="$route.params.oldHistory"></topNav>
    <div class="breadcrumb-back">
      <!-- 面包屑 -->
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>帮助中心</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="helper-content">
        <div class="helper-content-left">
          <div class="heiper-a">
            <img src="@/assets/img/pre/bg-left-menu.png" alt="">
            <div class="helper-b">
              <span class="helpcenter">帮助中心</span>
              <!-- <div class="help-c">
                <span class="help-d">Help Center</span>
              </div> -->
            </div>
          </div>
          <el-menu v-if="helpList.length > 0" text-color="#3E454D" active-text-color="#1371E7"
            :default-active="helpList[0].name" class="el-menu-vertical-demo" @open="handleOpen">
            <!-- @close="handleClose" -->
            <el-menu-item @click="helpListClick(item.id,item.name)" v-for="item in helpList" :index="item.name">
              <span slot="title">{{ item.name }}</span>
            </el-menu-item>
            <el-menu-item @click="toConnectUs()">
              <span>联系我们</span>
            </el-menu-item>
          </el-menu>
        </div>
        <div class="helper-content-right" v-loading="hotlssueLoading">
          <div class="helper-content-right-div">
            <div class="helper-title">
              {{hotlssueDataTitle}}
            </div>
            <div  v-if="hotlssueData.length > 0" class="hot-issue">
              <div class="hot-issue-content" v-for="(item, i) in hotlssueData" :key="i" @click="hotlssueItemClick(item)">
                <div class="hot-issue-content-item">{{ item.title }}</div>
                <div class="hot-issue-content-time">{{ item.updated.split("T")[0] + " " + item.updated.split("T")[1] }}
                </div>
              </div>
            </div>
            <div v-if="!hotlssueData.length > 0"
              style="text-align: center;padding-top: 120px;font-size: 18px;color: #909399;">
              暂无数据
            </div>
          </div>
          <!--分页组件-->
          <el-pagination style="margin: 32px 0 48px 0;text-align: center;" background @size-change="sizeChangeHandle"
            @current-change="currentChangeHandle" :current-page="page.pageIndex" :page-sizes="[10, 20, 50, 100]"
            :page-size="page.pageSize" :total="page.totalPage" layout="prev, pager, next">
          </el-pagination>
        </div>
      </div>
    <footerBottom></footerBottom>
    </div>
  </div>
</template>

<script>
import topNav from "@/components/nav/nav.vue"
import { httpNPGet, httpGet } from "@/utils/httpRequest";
import footerBottom from "@/components/footer/footer.vue"
export default {
  name: "Helper",
  data() {
    return {
      pageIndex: 4,
      //分页对象
      page: {
        //当前页
        pageIndex: 1,
        //每页显示数据
        pageSize: 5,
        //总页数
        totalPage: 0,
      },
      helpType: "",
      helpList: [],
      // list数据
      hotlssueData: [],
      // list标题
      hotlssueDataTitle: "",
      // 加载
      hotlssueLoading: true,
    }
  },
  methods: {
    // 获取帮助中心列表
    getHelpList() {
      this.hotlssueLoading = true
      httpNPGet("/news-type/help-center-menu", (res) => {
        // console.log(res.data)
        for (const index in res.data) {
          this.helpList.push(res.data[index])
        }
        // console.log(this.helpList)
        if(this.helpList[0]&&this.helpList[0]){
          this.helpListClick(this.helpList[0].id,this.helpList[0].name)
        }
      }, (err) => {
        // // console.log(err)
      })
    },
    // 获取咨询列表
    helpListClick(id,title) {
      httpGet("/news-info/list", {
        typeId: id,
        size: this.page.pageSize,
        current: this.page.pageIndex,
      }, (res) => {
        this.page.totalPage = res.data.total
        this.hotlssueData = res.data.records
        // console.log(title);
        this.hotlssueDataTitle = title
        this.hotlssueLoading = false
      }, (err) => {
        // // console.log(err)
      })
    },
      // 跳转具体咨询讯息页面
    hotlssueItemClick(data){
      this.$router.push({
          name: 'policyDetails',
          query: {
              id: data.id
          }
      })
    },
    // 跳转联系我们
    toConnectUs() {
      // console.log(1)
      this.$router.push("/helper/ConnectUs")
    },
    // 每页数
    sizeChangeHandle(val) {
      this.page.pageSize = val;
      this.page.pageIndex = 1;
    },
    // 当前页
    currentChangeHandle(val) {
      this.page.pageIndex = val
    },
    handleOpen(key, keyPath) {
      // console.log(key, keyPath);

    },
    handleClose(key, keyPath) {
      // console.log(key, keyPath);
    },
  },
  mounted() {
    this.getHelpList();
  },
  components: {
    topNav,
    footerBottom
  },
}
</script>

<style lang="scss" scoped>
.helper {
  // background-color: rgb(247, 247, 247);

  ::v-deep .el-menu {
    text-align: left;
  }

  ::v-deep .el-menu-item {
    padding-left: 0 !important;
    padding-right: 0;
    font-size: 18px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #3E454D;
    margin-bottom: 10px;
    border-left: 3px solid #fff;
  }

  ::v-deep .el-menu-item.is-active {
    background: linear-gradient(90deg, #E8F1FB 0%, rgba(9, 112, 213, 0) 100%);
    border-left: 3px solid #1371E7;
    box-sizing: border-box;
    border-radius: 1%;
    font-size: 18px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #1371E7;
  }

  ::v-deep .el-menu-item:focus,
  .el-menu-item:hover {
    background: linear-gradient(90deg, #E8F1FB 0%, rgba(9, 112, 213, 0) 100%);
    border-left: 3px solid #1371E7;
    box-sizing: border-box;
    border-radius: 1%;
    font-size: 18px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #1371E7 !important;
  }

  ::v-deep .el-menu-item span {
    padding-left: 24px;
  }

  // .nav {
  //   width: 100%;
  //   height: 7.5rem;
  //   background: #303030;
  // }

  .helper-content {
    z-index: 100;
    // min-width: 1200px;
    width: 1200px;
    // margin: 0 19.27083%;
    margin: 0 auto;
    //height: 200px;
    display: flex;
    justify-content: space-between;
    // background-color: #FFFFFF;
    height: 70vh;
    margin-bottom: 80px;

    .helper-content-right {
      //width: 100%;
      width: 79.4915%;
      overflow: auto;
      padding: 0 20px;
      margin-left: 20px;
      height: 37rem;
      background: #FFFFFF;
      opacity: 1;
      //border: 1px solid #EDEFF2;
      border-radius: 10px;

      .helper-content-right-div {
        width: 100%;

        .helper-title {
          text-align: left;
          font-size: 26px;
          color: #1e9eff;
          padding: 20px 0;
          border-bottom: 1px solid #d5d5d584;
        }

        //height: 200px;
        .hot-issue {
          margin-top: 10px;
          padding: 0 10px;
          height: 24rem;

          ::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
            color: #FFF;
            width: 66px;
            height: 36px;
            line-height: 36px;
            background: #1371E7;
            border-radius: 2px 2px 2px 2px;
            opacity: 1;
            border: 1px solid #EDEFF2;
          }

          ::v-deep .el-pagination.is-background .btn-next,
          ::v-deep .el-pagination.is-background .btn-prev,
          ::v-deep .el-pagination.is-background .el-pager li {
            width: 66px;
            height: 36px;
            background: #FFFFFF;
            border-radius: 2px 2px 2px 2px;
            opacity: 1;
            border: 1px solid #EDEFF2;
            border-radius: 2px;
          }

          .hot-issue-content {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid #EDEFF2;
            cursor: pointer;

            .hot-issue-content-item {
              height: 4.5625rem;
              display: flex;
              align-items: center;
            }

            .hot-issue-content-item::before {
              content: "";
              display: inline-block;
              width: 1.25rem;
              height: 1.25rem;
              background: url('~@/assets/img/pre/icon-message-1.png');
              background-size: 100% 100%;
              margin-right: 0.75rem;
            }

            .hot-issue-content-time {
              font-size: 1.125rem;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: #667280;
            }
          }
        }
      }
    }

    .helper-content-left {
      width: 17.7966%;
      height: 37rem;
      background: #FFFFFF;
      box-shadow: 0px 0.125rem 0.25rem 0px rgba(3, 27, 78, 0.06);
      border-radius: 10px;
      opacity: 1;
      border: 0.0625rem solid #EDEFF2;

      .heiper-a {
        height: 75px;
        position: relative;
        border-radius: 10px;

        img {
          width: 100%;
          height: 100%;
          border-radius: 10px 10px 0 0;
        }

        .helper-b {
          position: absolute;
          top: 1.375rem;
          left: 6.6667%;

          .helpcenter {
            height: 2.4375rem;
            font-size: 1.75rem;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 2.0625rem;

          }

          .help-c {
            width: 100%;
            height: 3rem;
            overflow: hidden;
          }

          .help-d {
            height: 2.8125rem;
            font-size: 2rem;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: rgba(255, 255, 255, 0.6);
            line-height: 2.375rem;
          }
        }

      }

    }
  }

  .breadcrumb-back {
    position: absolute;
    // height: 7.5rem;
    // background-image: url("~@/assets/img/pre/bg-help.png");
    width: 100%;
    // height: 100vh;
    background-color: rgb(247, 247, 247);
    z-index: 1;

    .el-breadcrumb {
      min-width: 1200px;
      font-size: 0.8125rem;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #3E454D;
      line-height: 4rem;
      width: 61.458%;
      height: 4rem;
      margin: 0 auto 0 auto;
    }

    ::v-deep .el-breadcrumb__inner.is-link {
      font-size: 0.8125rem !important;
      font-weight: 400 !important;
    }
  }

  .footer {
    padding-top: 30px;
    padding-bottom: 20px;
    box-sizing: border-box;
    width: 100%;
    // height: 13rem;
    opacity: 1;
    background-image: url('~@/assets/img/pre/bg-footer.png');

    .footer-content {
      width: 61.458%;
      display: flex;
      justify-content: space-between;
      margin: 0 auto 1.5rem auto;
      padding: 2rem 0 0 0;
      align-items: center;

      .footer-top-line-1 {
        width: inherit;
        height: 0;
        opacity: 0.4;
        border: 0.0625rem solid;
        border-image: linear-gradient(191deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) 2 2;
      }

      .footer-top-line-2 {
        width: inherit;
        height: 0;
        opacity: 0.4;
        border: 0.0625rem solid;
        border-image: linear-gradient(191deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) 2 2;
        transform: rotate(180deg);
      }

      .footer-top-line-font {
        margin: 0 1.1%;
        font-size: 0.75rem;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.47);
        line-height: 1.25rem;
        width: 70%;
      }

      @media screen and (max-width: 1539px) {
        .footer-top-line-font {
          width: 120%;
        }
      }

      @media screen and (max-width: 1400px) {
        .footer-top-line-font {
          width: 130%;
        }
      }
    }

    .footer-detail {
      width: 61.458%;
      //background-color: red;
      display: flex;
      justify-content: space-between;
      margin: 0 auto;
      text-align: left;
      font-size: 0.875rem;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 1.25rem;
      align-items: center;

      .line {
        width: 1px;
        height: 0.875rem;
        background: #FFFFFF;
        opacity: 0.2;
      }

      .code-logo {
        width: 35.87%;
      }

      .code-img {
        // width: 100%;
        height: 5.9375rem;
      }

      ul {
        list-style-type: none;
        margin-top: 1.75rem;
        display: flex;

        justify-content: space-between;

      }
    }
  }

  .bottom {
    width: 100%;
    height: 2.75rem;
    background: #181B20;
    font-size: 0.8125rem;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 1.75rem;

    .bottom-content {
      width: 61.458%;
      height: 100%;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .bottom-right {
      display: flex;
      align-items: center;
    }

    .bottom-right::before {
      content: "";
      display: inline-block;
      width: 1.25rem;
      height: 1.25rem;
      background: url('~@/assets/img/pre/bradge.png');
      background-size: 100% 100%;
      margin-right: 1.375rem;
    }
  }
}</style>